import * as React from 'react';
import {Link} from 'gatsby';
import Layout from '../components/layout';
import styled from 'styled-components';
import {COLORS, breakpointsMediaQueries} from '../style/my-theme';

const codeStyles = {
  color: '#8A6534',
  padding: 4,
  backgroundColor: '#FFF4DB',
  fontSize: '1.25rem',
  borderRadius: 4
};

const NotFoundSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5%;
  font-family: 'Press Start 2P';
  background-image: url('/images/footer-stickers.jpeg');
  text-align: center;

  ${breakpointsMediaQueries.lg} {
    flex-direction: column;
    justify-content: space-around;
    padding: 5% 5%;
    .img img {
      width: 70vw;
      height: auto;
    }
    h1 {
      font-size: 50px;
    }
    h2 {
      font-size: 25px;
    }
    text a:active {
      color: red;
      text-decoration: underline;
    }
  }

  h1 {
    color: red;
    font-size: 100px;
  }
  h2 {
    font-size: 50px;
    color: ${COLORS.yellow};
  }
  h3 {
    color: ${COLORS.yellow};
  }
  h1,
  h2,
  h3 {
    margin-bottom: 40px;
  }
  .text {
    a {
      text-decoration: none;
      margin-right: 20px;
      &:hover {
        color: red;
        text-decoration: underline;
      }
    }
  }
`;

const NotFoundPage = () => {
  return (
    <Layout>
      <NotFoundSection>
        <div className="img">
          <img src="/images/backToTheHomepage.webp" alt="Back to the Homepage" />
          <img src="/images/Delorean.webp" alt="El Delorean, El Doc y Marti McFly" />
        </div>
        <div className="text">
          <h1>404</h1>
          <h2>PAGE NOT FOUND</h2>
          <h3>BACK TO HOME?</h3>
          <Link to="/" className="yes">
            YES
          </Link>
          <a href="https://www.youtube.com/watch?v=G3AfIvJBcGo" target="_blank">
            NO
          </a>
        </div>
      </NotFoundSection>
      {process.env.NODE_ENV === 'development' ? (
        <>
          <br />
          Try creating a page in <code style={codeStyles}>src/pages/</code>.
          <br />
        </>
      ) : null}
    </Layout>
  );
};

export default NotFoundPage;
